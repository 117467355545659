import React from "react"
import * as styles from "./reach.module.scss"
import ReachTable from "./ReachTable/ReachTable"
import { useIntl } from "gatsby-plugin-intl"

const Reach = () => {
  const intl = useIntl()
  return (
    <div className={styles.reach__container}>
      <div className={styles.reach__title}>
        {intl.formatMessage({ id: "government.reach.title" })}
      </div>
      <p className={styles.reach__description}>
        {intl.formatMessage({ id: "government.reach.description" })}
      </p>
      <ReachTable></ReachTable>
    </div>
  )
}

export default Reach
