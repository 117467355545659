import React from "react"
import * as styles from "./action.module.scss"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Government/whatWeDo.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Action = () => {
  const { image } = useStaticQuery(getImage)
  const intl = useIntl()

  return (
    <div className={styles.action__container}>
      <div className={styles.action__title}>
        {intl.formatMessage({ id: "government.action.title" })}
      </div>
      <p className={styles.action__description}>
        {intl.formatMessage({ id: "government.action.description" })}
      </p>

      <div className={styles.picture_container}>
        <div className={styles.container__text}>
          <div className={styles.picture_text}>
            {intl.formatMessage({ id: "government.action.pictureText" })}
          </div>
          <Link to="/services">
            <button className={styles.button_services}>
              {intl.formatMessage({ id: "Services" })}
            </button>
          </Link>
        </div>
        <Image
          fluid={image.childImageSharp.fluid}
          className={styles.picture}
          alt="Government Actions"
        ></Image>
      </div>
    </div>
  )
}

export default Action
