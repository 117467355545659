import React from "react"
import * as styles from "./performance.module.scss"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Government/performance.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Performance = () => {
  const { image } = useStaticQuery(getImage)
  const intl = useIntl()
  return (
    <div className={styles.performance__container}>
      <div className={styles.performance__title}>
        {intl.formatMessage({ id: "government.performance.title" })}
      </div>
      <p className={styles.performance__description}>
        {intl.formatMessage({ id: "government.performance.description1" })}
        <span className={styles.description_bold}>
          {intl.formatMessage({ id: "government.performance.description3" })}
        </span>
        {intl.formatMessage({ id: "government.performance.description2" })}
      </p>

      <div className={styles.picture_container}>
        <Image
          fluid={image.childImageSharp.fluid}
          className={styles.picture}
          alt="Government Performance"
        ></Image>
        <div className={styles.container__text}>
          <div className={styles.picture_text}>
            {intl.formatMessage({ id: "government.performance.picture.text" })}
          </div>
          <Link to="/portfolio">
            <button className={styles.button_projects}>
              {intl.formatMessage({ id: "Projects" })}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Performance
