import React from "react"
import * as styles from "./details.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

const getLogo = graphql`
  {
    logo: file(relativePath: { eq: "logo_transparent@2x.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Details = () => {
  const { logo } = useStaticQuery(getLogo)
  const intl = useIntl()
  return (
    <div className={styles.details__container}>
      <Image
        fluid={logo.childImageSharp.fluid}
        style={{ width: "60%" }}
        alt="Competdac Logo"
      />
      <ul
        style={{ listStyleType: "none", marginTop: "3%", paddingLeft: "0px" }}
      >
        <li>
          <span className={styles.name__details}>
            {intl.formatMessage({ id: "contact.contactDetails.duns" })}
          </span>
          <span className={styles.value__details}> 565502592 </span>
        </li>
        <li>
          <span className={styles.name__details}>
            {intl.formatMessage({ id: "contact.contactDetails.cage" })}
          </span>
          <span className={styles.value__details}> 1D06L </span>
        </li>
        <li>
          <span className={styles.name__details}>
            {intl.formatMessage({ id: "contact.contactDetails.vat" })}
          </span>
          <span className={styles.value__details}> RO26547827 </span>
        </li>
        <li>
          <span className={styles.name__details}> MAIL: </span>
          <span className={styles.value__details}> contact@competdac.ro </span>
        </li>
      </ul>
    </div>
  )
}

export default Details
