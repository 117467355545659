import React from "react"
import * as styles from "./government.module.scss"
import Approach from "./Approach/Approach"
import Performance from "./Performance/Performance"
import Reach from "./Reach/Reach"
import Action from "./Action/Action"
import Details from "./Details/Details"
import About from "./About/About"
import { Row, Col } from "react-bootstrap"
import Footer from "../Homepage/Footer/Footer"
import GovernmentHeader from "./GovernmentHeader/GovernmentHeader"
import SidebarMenu from "../Sidebar/SidebarMenu"

const Government = () => {
  return (
    <div className={styles.governmentPage__container}>
      <SidebarMenu name="GOVERNMENT" />
      <div>
        <GovernmentHeader></GovernmentHeader>
        <div className={styles.row__container}>
          <div className={styles.first__container}>
            <Row style={{ alignItems: "center" }}>
              <Col md={5}>
                {" "}
                <Details />
              </Col>
              <Col md={7}>
                <About />
              </Col>
            </Row>
          </div>
        </div>
        <Approach />
        <Reach />
        <Action />
        <Performance />
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Government
