import React from "react"
import * as styles from "./approach.module.scss"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Government/approach.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Approach = () => {
  const { image } = useStaticQuery(getImage)
  const intl = useIntl()

  return (
    <div className={styles.approach__container}>
      <div className={styles.approach__title}>
        {intl.formatMessage({ id: "government.approach.title" })}
      </div>
      <div className={styles.approach_rectangle__container}>
        <div className={styles.rectangle}>
          <div className={styles.rectangle__title}>
            {intl.formatMessage({ id: "government.approach.tab1.title" })}
          </div>
          <div className={styles.rectangle__content}>
            {intl.formatMessage({ id: "government.approach.tab1.description" })}
          </div>
        </div>
        <div className={styles.rectangle}>
          <div className={styles.rectangle__title}>
            {intl.formatMessage({ id: "government.approach.tab2.title" })}
          </div>
          <div className={styles.rectangle__content}>
            {intl.formatMessage({ id: "government.approach.tab2.description" })}
          </div>
        </div>
        <div className={styles.rectangle}>
          <div className={styles.rectangle__title}>
            {intl.formatMessage({ id: "government.approach.tab3.title" })}
          </div>
          <div className={styles.rectangle__content}>
            {intl.formatMessage({ id: "government.approach.tab3.description" })}
          </div>
        </div>
      </div>

      <div className={styles.picture_container}>
        <Image
          fluid={image.childImageSharp.fluid}
          className={styles.picture}
          alt="Government Approach"
        ></Image>
        <div className={styles.container__text}>
          <div className={styles.picture_text}>
            {intl.formatMessage({ id: "government.approach.picture.text" })}
          </div>
          <Link to="/contact">
            <button className={styles.button_contact}>
              {intl.formatMessage({ id: "government.approach.picture.button" })}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Approach
