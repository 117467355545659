import React from "react"
import * as styles from "./reachTable.module.scss"
import { Row, Col } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"

const ReachTable = () => {
  const intl = useIntl()
  return (
    <div className={styles.reachTable__container}>
      <Row>
        <Col md={2} xs={3} className={styles.header__names}>
          <span>{intl.formatMessage({ id: "EUROPE" })}</span>
        </Col>
        <Col md={10} xs={9}>
          <Row style={{ margin: "0" }}>
            <Col md={2} xs={4} className={styles.content__table}>
              {intl.formatMessage({ id: "Romania" })} <br></br>
              {intl.formatMessage({ id: "Hungary" })}
            </Col>
            <Col md={2} xs={4} className={styles.content__table}>
              {intl.formatMessage({ id: "Germany" })} <br></br>
              {intl.formatMessage({ id: "Latvia" })}
            </Col>
            <Col md={2} xs={4} className={styles.content__table}>
              {intl.formatMessage({ id: "Belgium" })} <br></br>
              {intl.formatMessage({ id: "Poland" })}
            </Col>
            <Col md={2} xs={4} className={styles.content__table}>
              {intl.formatMessage({ id: "Estonia" })} <br></br>
              {intl.formatMessage({ id: "Bulgaria" })}
            </Col>
            <Col md={2} xs={4} className={styles.content__table}>
              {intl.formatMessage({ id: "Lithuania" })} <br></br>
              {intl.formatMessage({ id: "Netherlands" })}
            </Col>
            <Col md={2} xs={4} className={styles.content__table}>
              {intl.formatMessage({ id: "Italy" })}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: "4%" }}>
        <Col md={2} xs={3} className={styles.header__names}>
          <span>AFRICA</span>
        </Col>
        <Col md={10} xs={9}>
          <Row>
            <Col md={2} xs={4} className={styles.content__table}>
              Djibouti
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default ReachTable
