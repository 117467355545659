// extracted by mini-css-extract-plugin
export var approach__container = "approach-module--approach__container--yfYb5";
export var approach__title = "approach-module--approach__title--1aimo";
export var approach_rectangle__container = "approach-module--approach_rectangle__container--3PTxs";
export var rectangle = "approach-module--rectangle--xtYa7";
export var rectangle__title = "approach-module--rectangle__title--uYyEA";
export var rectangle__content = "approach-module--rectangle__content--3F67x";
export var picture_container = "approach-module--picture_container--3Ob5c";
export var picture = "approach-module--picture--3P1im";
export var container__text = "approach-module--container__text--GXHns";
export var picture_text = "approach-module--picture_text--13PKh";
export var button_contact = "approach-module--button_contact--3vqOI";