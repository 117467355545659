import React from "react"
import * as styles from "./about.module.scss"
import { useIntl } from "gatsby-plugin-intl"

const About = () => {
  const intl = useIntl()
  return (
    <div className={styles.about__container}>
      <h1 className={styles.about__title}>
        {intl.formatMessage({ id: "government.about.title" })}
      </h1>
      <p className={styles.about__description}>
        {intl.formatMessage({ id: "government.about.description" })}
      </p>
    </div>
  )
}

export default About
